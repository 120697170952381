import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import * as XLSX from "xlsx";

function formatIndonesianDate(date) {
  const days = ["Minggu", "Senin", "Selasa", "Rabu", "Kamis", "Jumat", "Sabtu"];
  const months = [
    "Januari",
    "Februari",
    "Maret",
    "April",
    "Mei",
    "Juni",
    "Juli",
    "Agustus",
    "September",
    "Oktober",
    "November",
    "Desember",
  ];

  const dayName = days[date.getDay()];
  const day = date.getDate();
  const monthName = months[date.getMonth()];
  const year = date.getFullYear();

  return `${dayName}, ${day} ${monthName} ${year}`;
}

function formatIndonesianDateWithoutDay(date) {
  const months = [
    "Januari",
    "Februari",
    "Maret",
    "April",
    "Mei",
    "Juni",
    "Juli",
    "Agustus",
    "September",
    "Oktober",
    "November",
    "Desember",
  ];

  const day = date.getDate();
  const monthName = months[date.getMonth()];
  const year = date.getFullYear();

  return `${day} ${monthName} ${year}`;
}

const DBFetcherUser = ({
  onFetchTargetItems,
  currentItems = [],
  searchQuery = "",
  onRowClick,
  user,
  selectedRequest,
}) => {
  const [data, setData] = useState([]);
  const [redHighlightedAssets, setRedHighlightedAssets] = useState([]);
  const [foundedAssets, setFoundedAssets] = useState([]);
  const [roomFilter, setRoomFilter] = useState("");
  const [rooms, setRooms] = useState([]);
  const [roomSearch, setRoomSearch] = useState("");
  const location = useLocation();

  // Fetch assets
  useEffect(() => {
    const fetchUrl = "https://amipartnership.com/api/assets";

    fetch(fetchUrl)
      .then((response) => response.json())
      .then((data) => {
        setData(data.assets);
        if (onFetchTargetItems) {
          onFetchTargetItems(data.assets.map((asset) => asset.no_rfid));
        }
      })
      .catch((err) => console.error(err));
  }, [onFetchTargetItems, user, selectedRequest]);

  // Fetch rooms
  useEffect(() => {
    const fetchRooms = () => {

      fetch("https://amipartnership.com/api/all-ruangan")
        .then((response) => {
          if (!response.ok) throw new Error("Network response was not ok");
          return response.json();
        })
        .then((data) => {
          setRooms(data); // Store the data as is
          console.log("Fetched rooms data:", data); // Log the raw data
        })
        .catch((err) => console.error("Error fetching rooms:", err));
    };
    fetchRooms();
  }, []);

  useEffect(() => {
    const normalizedCurrentItems = currentItems.map((item) =>
      item.trim().toLowerCase()
    );

    // Filter current items by room filter
    const filteredCurrentItems = normalizedCurrentItems.filter((item) => {
      const asset = data.find(
        (asset) => asset.no_rfid.trim().toLowerCase() === item
      );
      return (
        asset &&
        `${asset.gedung} - ${asset.lantai} - ${asset.ruangan}`
          .toLowerCase()
          .includes(roomFilter.toLowerCase())
      );
    });

    const redAssets = data.filter(
      (asset) =>
        location.pathname === "/scan-room" &&
        !filteredCurrentItems.includes(asset.no_rfid.trim().toLowerCase())
    );
    setRedHighlightedAssets(redAssets);

    const foundAssets = data.filter(
      (asset) =>
        location.pathname === "/scan-room" &&
        filteredCurrentItems.includes(asset.no_rfid.trim().toLowerCase())
    );
    setFoundedAssets(foundAssets);
  }, [data, currentItems, roomFilter, location.pathname]);

  const handleRoomFilterChange = (e) => {
    setRoomFilter(e.target.value);
  };

  const handleRoomSearchChange = (e) => {
    setRoomSearch(e.target.value);
  };

  const filteredData = data
  .filter((asset) =>
    asset.no_rfid.toLowerCase().includes(searchQuery.toLowerCase()) ||
    asset.nama_asset.toLowerCase().includes(searchQuery.toLowerCase())
  )
  .filter((asset) => {
    // Access the nested structure correctly
    const gedung = asset.gedung; // Adjust according to your data structure
    const lantai = asset.lantai; // Adjust according to your data structure
    const ruangan = asset.ruangan; // Adjust according to your data structure

    // Check if the combined string includes the roomFilter
    return `${gedung} - ${lantai} - ${ruangan}`.toLowerCase().includes(roomFilter.toLowerCase());
  });


  const handleDownloadPDF = () => {
    const pdf = new jsPDF({ orientation: "landscape" });
    const currentDate = new Date();
    const formattedDate = `${currentDate.getFullYear()}-${(
      currentDate.getMonth() + 1
    )
      .toString()
      .padStart(2, "0")}-${currentDate.getDate().toString().padStart(2, "0")}`;

    pdf.setFont("helvetica", "bold");
    pdf.setFontSize(16);

    const headerText = "BERITA KEHILANGAN ASET";
    const textWidth = pdf.getTextWidth(headerText);
    const pageWidth = pdf.internal.pageSize.getWidth();
    const xPosition = (pageWidth - textWidth) / 2;
    const yPosition = 10;

    pdf.text(headerText, xPosition, yPosition);
    pdf.line(xPosition, yPosition + 2, xPosition + textWidth, yPosition + 2);

    pdf.setFont("helvetica", "normal");
    pdf.setFontSize(12);

    const metadataText = `BERITA KEHILANGAN ASET Ini dibuat pada ${formatIndonesianDate(
      new Date()
    )} oleh ${user.username}. Dengan ini menyatakan bahwa telah kehilangan aset sebagai berikut;`;

    const lines = pdf.splitTextToSize(metadataText, 270);
    pdf.text(lines, 10, 20);

    const tableColumn = [
      "No RFID",
      "Nama Aset",
      "Kondisi",
      "Tanggal Perawatan",
      "Harga Perolehan",
      "Nama PIC",
      "Detail PIC",
      "Status Asset",
      "No Inventaris",
      "Kode Asset",
      "No Contract",
      "Keterangan",
      "ID Lokasi",
      "Gedung",
      "Lantai",
      "Ruangan",
    ];

    const tableRows = [];

    // Filter redHighlightedAssets by roomFilter
    const filteredRedAssets = redHighlightedAssets.filter((asset) =>
      `${asset.gedung} - ${asset.lantai} - ${asset.ruangan}`
        .toLowerCase()
        .includes(roomFilter.toLowerCase())
    );

    filteredRedAssets.forEach((asset) => {
      const assetData = [
        asset.no_rfid,
        asset.nama_asset,
        asset.kondisi,
        formatIndonesianDate(new Date(asset.tanggal_perawatan)),
        asset.harga_perolehan,
        asset.nama_pic,
        asset.detail_pic,
        asset.status_asset,
        asset.no_inventaris,
        asset.kode_asset,
        asset.no_contract,
        asset.keterangan,
        asset.id_lokasi,
        asset.gedung,
        asset.lantai,
        asset.ruangan,
      ];
      tableRows.push(assetData);
    });

    pdf.autoTable({
      startY: 40,
      head: [tableColumn],
      body: tableRows,
    });

    pdf.text(
      "Demikian Berita Acara ini dibuat dengan sebenar - benarnya.",
      10,
      pdf.autoTable.previous.finalY + 10
    );

    pdf.text(
      `${user.username}, ${formatIndonesianDateWithoutDay(new Date())}`,
      10,
      pdf.autoTable.previous.finalY + 20
    );

    pdf.save(`berita-kehilangan-aset-${formattedDate}.pdf`);
  };



  const handleDownloadExcel = () => {
    // Filter redHighlightedAssets by roomFilter
    const filteredRedAssets = redHighlightedAssets.filter((asset) =>
      `${asset.gedung} - ${asset.lantai} - ${asset.ruangan}`
        .toLowerCase()
        .includes(roomFilter.toLowerCase())
    );

    const worksheet = XLSX.utils.json_to_sheet(filteredRedAssets);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Berita Kehilangan");
    XLSX.writeFile(workbook, "berita_kehilangan_aset.xlsx");
  };

  const handleDownloadFoundedPDF = () => {
    const pdf = new jsPDF({ orientation: "landscape" });
    const currentDate = new Date();
    const formattedDate = `${currentDate.getFullYear()}-${(
      currentDate.getMonth() + 1
    )
      .toString()
      .padStart(2, "0")}-${currentDate.getDate().toString().padStart(2, "0")}`;

    pdf.setFont("helvetica", "bold");
    pdf.setFontSize(16);

    const headerText = "BERITA PENEMUAN ASET";
    const textWidth = pdf.getTextWidth(headerText);
    const pageWidth = pdf.internal.pageSize.getWidth();
    const xPosition = (pageWidth - textWidth) / 2;
    const yPosition = 10;

    pdf.text(headerText, xPosition, yPosition);
    pdf.line(xPosition, yPosition + 2, xPosition + textWidth, yPosition + 2);

    pdf.setFont("helvetica", "normal");
    pdf.setFontSize(12);

    const metadataText = `BERITA PENEMUAN ASET Ini dibuat pada ${formatIndonesianDate(
      new Date()
    )} oleh ${user.username}. Dengan ini menyatakan bahwa telah ditemukan aset sebagai berikut;`;

    const lines = pdf.splitTextToSize(metadataText, 180);
    pdf.text(lines, 10, 20);

    const tableColumn = [
      "No RFID",
      "Nama Aset",
      "Kondisi",
      "Tanggal Perawatan",
      "Harga Perolehan",
      "Nama PIC",
      "Detail PIC",
      "Status Asset",
      "No Inventaris",
      "Kode Asset",
      "No Contract",
      "Keterangan",
      "ID Lokasi",
      "Gedung",
      "Lantai",
      "Ruangan",
    ];

    const tableRows = [];

    // Filter foundedAssets by roomFilter
    const filteredFoundedAssets = foundedAssets.filter((asset) =>
      `${asset.gedung} - ${asset.lantai} - ${asset.ruangan}`
        .toLowerCase()
        .includes(roomFilter.toLowerCase())
    );

    filteredFoundedAssets.forEach((asset) => {
      const assetData = [
        asset.no_rfid,
        asset.nama_asset,
        asset.kondisi,
        formatIndonesianDate(new Date(asset.tanggal_perawatan)),
        asset.harga_perolehan,
        asset.nama_pic,
        asset.detail_pic,
        asset.status_asset,
        asset.no_inventaris,
        asset.kode_asset,
        asset.no_contract,
        asset.keterangan,
        asset.id_lokasi,
        asset.gedung,
        asset.lantai,
        asset.ruangan,
      ];
      tableRows.push(assetData);
    });

    pdf.autoTable({
      startY: 40,
      head: [tableColumn],
      body: tableRows,
    });

    pdf.text(
      "Demikian Berita Acara ini dibuat dengan sebenar - benarnya.",
      10,
      pdf.autoTable.previous.finalY + 10
    );

    pdf.text(
      `${user.username}, ${formatIndonesianDateWithoutDay(new Date())}`,
      10,
      pdf.autoTable.previous.finalY + 20
    );

    pdf.save(`berita-penemuan-aset-${formattedDate}.pdf`);
  };


  const handleDownloadFoundedExcel = () => {
    // Filter foundedAssets by roomFilter
    const filteredFoundedAssets = foundedAssets.filter((asset) =>
      `${asset.gedung} - ${asset.lantai} - ${asset.ruangan}`
        .toLowerCase()
        .includes(roomFilter.toLowerCase())
    );

    const worksheet = XLSX.utils.json_to_sheet(filteredFoundedAssets);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Berita Penemuan");
    XLSX.writeFile(workbook, "berita_penemuan_aset.xlsx");
  };

  return (
    <div>
      <div style={{ marginBottom: "10px" }}>
        {/* <label>
          Filter by Room:{" "}
          <input
            type="text"
            value={roomSearch}
            onChange={handleRoomSearchChange}
            placeholder="Search room  (contoh Gedung_1-Lantai_1-Ruangan_1)"
          />
        </label> */}
        <select value={roomFilter} onChange={handleRoomFilterChange}>
          <option value="">Select a room</option>
          {rooms
            .flatMap(
              (
                gedung // Flatten the structure
              ) =>
                gedung.Lantai.flatMap((lantai) =>
                  lantai.Ruangan.map((ruangan) => ({
                    gedung: gedung.Gedung,
                    lantai: lantai.Lantai,
                    ruangan: ruangan,
                  }))
                )
            )
            .filter(({ ruangan }) =>
              ruangan.toLowerCase().includes(roomSearch.toLowerCase())
            ) // Filter based on room search
            .map(
              (
                { gedung, lantai, ruangan },
                index // Map to options
              ) => (
                <option
                  key={index}
                  value={`${gedung} - ${lantai} - ${ruangan}`}
                >
                  {`${gedung} - ${lantai} - ${ruangan}`}
                </option>
              )
            )}
        </select>
      </div>

      <th colSpan="1">Total Aset: {filteredData.length}</th>
              {location.pathname === "/scan-room" && (
                <td
                  style={{
                    textAlign: "left",
                    position: "sticky",
                    top: 0,
                    background: "#fff",
                    zIndex: 10,
                    background: "var(--grey)",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      gap: "10px",
                      background: "var(--grey)",
                    }}
                  >
                    <button
                      className="modal-button edit"
                      onClick={handleDownloadPDF}
                      style={{ maxWidth: "150px", padding: "5px 10px" }}
                    >
                      Export PDF Berita Kehilangan
                    </button>
                    <button
                      className="modal-button edit"
                      onClick={handleDownloadExcel}
                      style={{
                        maxWidth: "150px",
                        padding: "5px 10px",
                      }}
                    >
                      Export Excel Berita Kehilangan
                    </button>
                    <button
                      className="modal-button edit"
                      onClick={handleDownloadFoundedPDF}
                      style={{
                        maxWidth: "150px",
                        padding: "5px 10px",
                      }}
                    >
                      Export PDF Berita Penemuan
                    </button>
                    <button
                      className="modal-button edit"
                      onClick={handleDownloadFoundedExcel}
                      style={{
                        maxWidth: "150px",
                        padding: "5px 10px",
                      }}
                    >
                      Export Excel Berita Kehilangan
                    </button>
                  </div>
                </td>
              )}

      <div style={{ overflowX: "auto" }}>
        <table id="databaseTable">
          <thead>
            <tr>
              <th colSpan="2"></th>
            </tr>
            
            <tr id="un-border" style={{border:"none"}}>
              
            </tr>
            <tr id="tb-header">
              <th>No RFID</th>
              <th>Nama Aset</th>
              <th>Kondisi</th>
              <th>Tanggal Perawatan</th>
              <th>Harga Perolehan</th>
              <th>Nama PIC</th>
              <th>Detail PIC</th>
              <th>Status Asset</th>
              <th>No Inventaris</th>
              <th>Kode Asset</th>
              <th>No Contract</th>
              <th>Keterangan</th>
              <th>ID Lokasi</th>
              <th>Gedung</th>
              <th>Lantai</th>
              <th>Ruangan</th>
            </tr>
          </thead>
          <tbody id="databaseTableBody">
            {filteredData.map((asset, index) => {
              const isRedHighlighted = redHighlightedAssets.some(
                (redAsset) => redAsset.no_rfid === asset.no_rfid
              );

              return (
                <tr

                  key={index}
                  onClick={() => onRowClick(asset)}
                  style={{ color: isRedHighlighted ? "red" : "var(--dark)" }}
                >
                  <td>{asset.no_rfid}</td>
                  <td>{asset.nama_asset}</td>
                  <td>{asset.kondisi}</td>
                  <td>{asset.tanggal_perawatan}</td>
                  <td>{asset.harga_perolehan}</td>
                  <td>{asset.nama_pic}</td>
                  <td>{asset.detail_pic}</td>
                  <td>{asset.status_asset}</td>
                  <td>{asset.no_inventaris}</td>
                  <td>{asset.kode_asset}</td>
                  <td>{asset.no_contract}</td>
                  <td>{asset.keterangan}</td>
                  <td>{asset.id_lokasi}</td>
                  <td>{asset.gedung}</td>
                  <td>{asset.lantai}</td>
                  <td>{asset.ruangan}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default DBFetcherUser;
